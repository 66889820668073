<template>
  <div id="task-list">
    <div v-if="loaded && accountsStages.length" class="container">
      <h4 class="title">
        Recommended for you
      </h4>

      <div class="task-list">
        <gc-stage-card-button
          v-for="accountsStage in accountsStages.slice(0, 3)"
          :key="accountsStage.id"
          class="task-list-stage-card global-components"
          :title="accountsStage.stage.title"
          :statuses="JSON.stringify(getStatuses(accountsStage))"
          @selected="goToStage(accountsStage)"
        />
      </div>
      <template v-if="accountsStages.length > 3">
        <b-button class="railyard-button" @click="goToRailyard()">
          See more recommended

          <fa-icon icon="chevron-right" class="icon" size="xs" />
        </b-button>
      </template>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TaskList',
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('companies', [
      'currentCompany',
    ]),
    ...mapGetters('stagelineV3', [
      'accountsStages',
    ]),
  },
  async mounted() {
    try {
      await this.getAndSetAccountsStages({
        page: { number: 1, size: 4 },
        include: 'stage',
        sort: ['stages_category_priority', 'stage_priority'],
        filter: {
          trackable_id_eq: this.currentCompany.id,
        },
      })
    } catch {
      // do nothing for now
    } finally {
      this.loaded = true
    }
  },
  methods: {
    ...mapActions('stagelineV3', [
      'getAndSetAccountsStages',
    ]),
    async goToStage(accountsStage) {
      await this.$router.push({ name: 'stage-viewer', params: {
          companyId: this.currentCompany.id,
          accountsStageId: accountsStage.id,
        },
      })
    },
    async goToRailyard() {
      await this.$router.push({ name: 'available-stages', params: {
          companyId: this.currentCompany.id,
        },
      })
    },
    getStatuses(accountsStage) {
      return accountsStage.current_slide ? ['inProgress'] : ['new']
    },
  },
}
</script>

<style scoped lang="scss">
.task-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1em;
}

.task-list-stage-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

:deep(.task-button) {
  width: 100%;
}

#task-list {
  .task-list::-webkit-scrollbar {
    display: none;
  }

  .container {
    margin-bottom: 5em;
    padding: 0;
  }

  .railyard-button {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin-top: 1em;
    font-size: 0.75em;
    float: right;
    padding: 0;
    background-color: $white;
    color: $ct-ui-primary;
    border: none;

    &:hover {
      transform: translateY(-1px);
    }

    &:focus {
      box-shadow: none;
      text-decoration: underline;
      text-decoration-color: $ct-ui-primary;
      text-decoration-style: solid;
    }
  }
}

// Override some of the Styles from global components
:deep(.p-tag-info) {
  background-color: #bbe0ff;
  color: #017bdf;
}
:deep(.p-tag-success) {
  background-color: #caf1d8;
  color: #188a42;
}
:deep(.p-tag-warning) {
  background-color: #feddc7;
  color: #ae510f;
}

</style>
