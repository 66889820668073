var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "task-list" } }, [
    _vm.loaded && _vm.accountsStages.length
      ? _c(
          "div",
          { staticClass: "container" },
          [
            _c("h4", { staticClass: "title" }, [
              _vm._v("\n      Recommended for you\n    "),
            ]),
            _c(
              "div",
              { staticClass: "task-list" },
              _vm._l(_vm.accountsStages.slice(0, 3), function (accountsStage) {
                return _c("gc-stage-card-button", {
                  key: accountsStage.id,
                  staticClass: "task-list-stage-card global-components",
                  attrs: {
                    title: accountsStage.stage.title,
                    statuses: JSON.stringify(_vm.getStatuses(accountsStage)),
                  },
                  on: {
                    selected: function ($event) {
                      return _vm.goToStage(accountsStage)
                    },
                  },
                })
              }),
              1
            ),
            _vm.accountsStages.length > 3
              ? [
                  _c(
                    "b-button",
                    {
                      staticClass: "railyard-button",
                      on: {
                        click: function ($event) {
                          return _vm.goToRailyard()
                        },
                      },
                    },
                    [
                      _vm._v("\n        See more recommended\n\n        "),
                      _c("fa-icon", {
                        staticClass: "icon",
                        attrs: { icon: "chevron-right", size: "xs" },
                      }),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }